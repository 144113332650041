import storage from './storage'
import { STORAGE_KEYS } from './constants'

export function getAccessToken() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    if (isAccessTokenValid(oauth2)) {
        // console.log('get access token : ', oauth2.value)
        return oauth2.value
    }
    return null;
}

export function setAccessToken(token) {
    storage.setObj(STORAGE_KEYS.tokenKey, token)
}

export function clearAccessToken() {
    storage.remove(STORAGE_KEYS.tokenKey)
}

export function getRefreshToken() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    if (isRefreshTokenValid(oauth2)) {
        // console.log('get refresh token : ', oauth2.refreshToken.value)
        return oauth2.refreshToken.value;
    }
    return null;
}

function isAccessTokenValid(oauth2) {
    // console.log('isAccessTokenValid,oauth2=%o', oauth2);
    return oauth2 && oauth2.value && !isExpired(oauth2.expiration);
}

function isRefreshTokenValid(oauth2) {
    // console.log('isRefreshTokenValid,oauth2=%o',oauth2);
    return oauth2 && oauth2.refreshToken
        && !isExpired(oauth2.refreshToken.expiration);
}

//是否过期
function isExpired(expiration) {
    const now = new Date().getTime();
    //提前1分钟判断
    const expired = expiration < now + 1 * 60 * 1000;
    // console.log('now+5miniutes=%o,expiration=%o,expired=%o', formatTime(now + 1 * 60 * 1000), formatTime(expiration), expired);
    return expired;
}


export function getRedirectParams() {
    const rdp = storage.get(STORAGE_KEYS.redirectParams)
    if (!rdp)
        return
    const redirectParams = JSON.parse(rdp);
    if (redirectParams) {
        return redirectParams
    }
    return null;
}

export function setRedirectParams(params) {
    storage.setObj(STORAGE_KEYS.redirectParams, params)
}

export function clearRedirectParams() {
    storage.remove(STORAGE_KEYS.redirectParams)
}
