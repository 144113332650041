//storage存储key
const STORAGE_KEYS = {
    tokenKey: "oauth2_token",
    accessTokenKey: "app_access_token",
    refreshTokenKey: "app_refresh_token",
    userInfo: "userInfo",
    redirectParams: "redirect_params",
}

// const SERVER_TEST = "http://localhost:9002/appapi"
const SERVER_TEST = "https://test.yfwl.xyz/appapi"
const SERVER_RELEASE = "https://xmf.yfwl.xyz/appapi"

// console.log('NODE_ENV ', process.env.NODE_ENV);
// const BASE_API = process.env.NODE_ENV == 'development' ? SERVER_TEST : SERVER_RELEASE
const BASE_API = SERVER_RELEASE

//不同公司版本的变量，根据不同分支来处理
const COMPANY = "MX/XMF"; // "MX/BEERICHER";

//Line 登录回调地址
const LOGIN_CALLBACK_HOST = {
    TEST: "https://localhost:8080",
    RELEASE: "https://m.tukship.com"
}

const LOGIN_CALLBACK = process.env.NODE_ENV == 'development' ? LOGIN_CALLBACK_HOST.TEST : LOGIN_CALLBACK_HOST.RELEASE
const LINE = {
    CLIENT_ID: "1656085016",//2001319047 1656085016
    LOGIN_CALLBACK,
    LOGIN_CHANNEL: 'Tukship'
}

export {
    STORAGE_KEYS,
    BASE_API,
    COMPANY,
    LINE
}