import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/utils/lang'
import VConsole from 'vconsole'

Vue.config.productionTip = false

// console.log('当前的语言是：', i18n.locale)

if (process.env.NODE_ENV !== 'production') {
  new VConsole()
}

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? 'tukship' : i18n.t(to.meta.title)
  next()
})
