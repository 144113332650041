<template>
  <div class="user_center">
    <div class="header">
      <img class="header_bg" src="../assets/images/bg.jpg" />
      <div class="header-top">
        <div class="header_user">
          <van-image round width="60px" height="60px" :src="(userInfo && userInfo.user.avatar) ||
            require('../assets/images/logo.jpeg')" />
          <div class="user">
            <div v-if="userInfo && userInfo.user.nickName" style="margin-bottom:5px;">{{ userInfo.user.nickName }}
              <van-icon name="edit" bind:tap="gotoProfile" />
            </div>
            <div v-else style="margin-bottom:5px">{{ $t('昵称') }} <van-icon name="edit" bind:tap="gotoProfile" /></div>
            <div style="font-size: 0.9em;color:#a8a8a8;">
              ID：{{ userInfo && userInfo.user.code26 }}</div>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="col" @click="goToBalance">
          <div class="value">-</div>
          <div class="name"><van-icon name="balance-list-o" /> {{ $t('交易记录') }}></div>
        </div>
        <!-- <div class="col" @click="goToBalance">
          <div class="value">{{ balance ? balance : 0 }}<span style="font-size:0.8em"> {{ $t('铢') }}</span></div>
          <div class="name"><van-icon name="balance-list-o" /> {{ $t('账户余额') }}></div>
        </div> -->
        <span class="divider"></span>
        <div class="col" @click="goToCoupons">
          <div class="value">{{ availableCouponCount ? availableCouponCount : '0' }}</div>
          <div class="name"><van-icon name="coupon-o" /> {{ $t('优惠券') }}></div>
        </div>
        <!-- <span class="divider"></span>
    <view class="col">
      <view class="value">{{ util.formatFriendlyNumber(cargoCount)}}</view>
      <view class="name"><van-icon name="gift-o" /> 包裹数</view>
    </view> -->
      </div>
    </div>

    <van-cell-group class="contact-info">
      <van-cell v-if="defaultContact && defaultContact.id" :title="$t('泰国收件地址')"
        :value="defaultContact.name + '/' + defaultContact.mobile" :to="{
          name: 'contactList',
          query: {
            scene: 'edit',
          },
        }" is-link></van-cell>
      <van-cell v-else :title="$t('泰国收件地址')" :value="$t('点击填写收件地址')" :to="{
        name: 'contactList',
        query: {
          scene: 'edit',
        },
      }" is-link></van-cell>

      <van-cell :title="$t('预报运单')" :to="{ name: 'cargoRegister' }" :value="$t('退回/保价/打木架/到付')" is-link></van-cell>
    </van-cell-group>

    <van-cell-group :title="$t('转运仓信息')" class="warehouse-info">
      <van-cell :title="$t('收件人')" :value="mWarehouse.receiver"></van-cell>
      <van-cell :title="$t('电话')" :value="mWarehouse.phone"></van-cell>
      <van-cell :title="$t('地址')" :value="mWarehouse.address"></van-cell>
    </van-cell-group>
    <div @click="copyWarehouse" class="copy-button">
      {{ $t("[复制转运仓信息]") }}
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { Image as VanImage, Cell, CellGroup, Dialog, Toast, Icon } from "vant";
import {
  fetchUserInfo,
  getDefaultContact,
  availbleCount,
} from "@/service/api.js";
import { EventBus } from "@/utils/event-bus.js";
import storage from "@/utils/storage";
import { STORAGE_KEYS } from "@/utils/constants";

import { clearAccessToken, getAccessToken } from "@/utils/token";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      show: false,
      defaultContact: {},
      availableCouponCount: 0,
      mWarehouse: {},
      userInfo: null,
      balance: 0
    };
  },
  mounted() {
    EventBus.$on("coupon", (msg) => {
      this.availbleCount();
    });
  },
  activated() {
    this.fetchUserInfo();
    this.getDefaultContact();
    this.availbleCount();
  },
  methods: {
    //跳转到余额
    goToBalance() {
      this.$router.push({
        name: 'balance',
        query: { balance: this.userInfo ? this.userInfo.user.balance : '' },
      })
    },
    goToCoupons() {
      this.$router.push({ name: 'coupon' })
    },
    coupontCount() {
      return this.availableCouponCount
        ? this.availableCouponCount + this.$t("张")
        : this.$t("无可用券");
    },

    async fetchUserInfo() {
      const res = await fetchUserInfo();
      if (res.success) {
        storage.setObj(STORAGE_KEYS.userInfo, res.data);
        const { logisticCompany, user } = res.data;
        this.mWarehouse = logisticCompany;
        this.userInfo = res.data;
        this.balance = user && user.displayBalance
      }
    },

    async getDefaultContact() {
      const res = await getDefaultContact();
      if (res.success) {
        this.defaultContact = res.data;
      }
    },

    async availbleCount() {
      const params = { status: 1, availble: true };
      const res = await availbleCount(params);
      if (res.success) {
        this.availableCouponCount = res.data;
      }
    },

    clearAccessToken() {
      clearAccessToken();

      const tokenkey = getAccessToken();
      // console.log(tokenkey);
      Toast("clear token");
    },

    copyWarehouse() {
      if (this.defaultContact && this.defaultContact.id) {
        const content =
          this.mWarehouse.receiver +
          "," +
          this.mWarehouse.phone +
          "," +
          this.mWarehouse.address;

        let oInput = document.createElement("input");
        oInput.value = content;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        document.execCommand("Copy"); // 执行浏览器复制命令
        Toast.success(this.$t("复制成功"));
        oInput.remove();
      } else {
        Dialog.confirm({
          confirmButtonText: this.$t("确认"),
          cancelButtonText: this.$t("取消"),
          message: this.$t("请您先填写泰国收件地址"),
        })
          .then(() => {
            this.$router.push({
              name: "Contact",
            });
          })
          .catch(() => { });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@backgroundColor: #f2f2f2;

.header {
  margin-bottom: 15px;
  /* height: 180px; */
}

.header-top {
  margin-bottom: 20px;
}

.header_bg {
  width: 100%;
  height: 180px;
  z-index: -999;
  display: block;
  position: fixed;
  left: 0;
}

.header_user {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 25px 0px 0px 25px;
}

.header-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.header-bottom .col {
  text-align: center;
  flex: 1;
  margin: 3px;
  /* border: 1px solid red; */
  /* width: 120px; */
}

.col .name {
  font-size: 0.9em;
  color: #a8a8a8;
}

.col .value {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.divider {
  width: 1px;
  height: 15px;
  background-color: #a8a8a8;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* margin-left: 15px; */
}

.user {
  padding-left: 15px;
}

.copy-button {
  padding: 10px;
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #569cd6;
}

.saveButton {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.saveButton button {
  width: 70%;
}

.warehouse-info {
  .van-cell__value {
    min-width: 70%;
  }
}

.contact-info {
  .van-cell__value {
    min-width: 60%;
  }
}
</style>