var EXPRESS_WAY_DICT = {
  '1': '陆运',
  '2': '海运'
}
var CARGO_TYPE_DICT = {
  'A': '普货',
  'B': '敏感货',
  'C': '特殊货'
}
var ISSUE_TYPE = {
  'insurance': '保险',
  'delivery_fee_on_receiver': '国内快递费到付',
  'woodenframe': '打木架',
  'reject': '拒收',
  'return': '退回',
  'reinforce_packing': '加固包装'
}
var ORDER_TYPE_DICT = {
  '1': '支付运费',
  '7': '充值',
  '8': '红包',
  '13': '提现',
  '99': '赔付'
}
var PAYMENT_STATUS_DICT = {
  '1': '待支付',
  '2': '已支付'
}
var ORDER_STATUS_DICT = {
  '1': '审核中',
  '5': '未通过,请联系客服',
  '6': '交易成功',
}
var PAY_CHANNEL_DICT = {
  '1': '微信',
  '2': '支付宝',
  '3': '余额',
  '4': '转账',
  '5': '领取',
}
var BILLING_METHOD_DICT = {
  '1': '按重量',
  '2': '按体积'
}
var CARGO_STATUS_DICT = {
  '1': '等待入仓',
  '2': '到达中转仓',
  '3': '发往泰国',
  '4': '到达泰国仓',
  '5': '到达泰国仓',
  '8': '已揽收',  
  '6': '派送上门',
  '7': '您已签收',
  '10': '已退回泰国仓',
  '12': '已取消'
}
var LOCAL_DELIVERY_STATUS_DICT = {
  // '0': '待打包',
  // '1': '已打包',
  '0': '出库中',
  '1': '出库中',
  '4': '发往泰国',
  '6': '到达泰国仓',
  '8': '已揽收',  
  '2': '派送上门',
  '3': '您已签收',
  '10': '已退回泰国仓',
  '12': '已取消'
}
var F_EXPRESS_COMPANY = {
  'jt': 'J&T',
  'flash': 'Flash',
  'Grab': 'Grab',
  'Staff': '司机',
  'OnSite': '自提',
  'zto': 'ZTO',
  'self_pick_up': '自提',
  'JD': 'JD',
  'Deliveree': 'Deliveree',
  'Ninja Van': 'Ninja Van',
  'CPL': 'CPL'
}
function convertExpressWay(val) {
  return EXPRESS_WAY_DICT[val+'']
}
function convertCargoType(val) {
  return CARGO_TYPE_DICT[val]
}
function convertCargoStatus(val, paymentStatus) {
  if (val == 5 && paymentStatus == 2) {
    return '等待快递揽件'
  } 
  return CARGO_STATUS_DICT[val+'']  
}
function convertLocalDeliveryStatus(val) {
  return LOCAL_DELIVERY_STATUS_DICT[val+'']  
}
function convertFExpressCompany(val) {
  if(val) {
    if(F_EXPRESS_COMPANY[val]) {
      return F_EXPRESS_COMPANY[val] 
    } else {
      return val;
    }
  }
  return '出库中';
}
function convertPaymentStatus(val) {
  return PAYMENT_STATUS_DICT[val+'']
}
function convertBillingMethod(val) {
  return BILLING_METHOD_DICT[val+'']
}
function convertOrderStatus(val) {
  return ORDER_STATUS_DICT[val+''] ? ORDER_STATUS_DICT[val+''] : val
}
function convertOrderType(val,payChannel) {
  console.log('val=%o,paychannel=%o',val,payChannel)
  if(val == 8 && payChannel == 6) {
    return "推广奖励";
  }
  return ORDER_TYPE_DICT[val+''] ? ORDER_TYPE_DICT[val+''] : val
}
function convertPayChannel(val) {
  return PAY_CHANNEL_DICT[val+'']
}
function convertIssueType(val) {
  return ISSUE_TYPE[val+'']
}
module.exports = {
  convertExpressWay: convertExpressWay,
  convertCargoType: convertCargoType,
  convertCargoStatus: convertCargoStatus,
  convertPaymentStatus: convertPaymentStatus,
  convertBillingMethod: convertBillingMethod,
  convertLocalDeliveryStatus: convertLocalDeliveryStatus,
  convertFExpressCompany: convertFExpressCompany,
  convertOrderType: convertOrderType,
  convertPayChannel: convertPayChannel,
  convertOrderStatus: convertOrderStatus,
  convertIssueType: convertIssueType
};