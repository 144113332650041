<template>
  <div>
    <van-panel :title="$t('请以英文或泰文填写收件人姓名和地址')">
      <div>
        <div class="contactor__name-wrapper van-hairline--bottom" style="margin: 0px 16px">
          <div>
            <van-field size="large" v-model="contact.name" :placeholder="$t('姓名')" clearable />
          </div>
          <div>
            <van-field size="large" :placeholder="$t('电话')" type="tel" v-model="contact.mobile" clearable />
          </div>
        </div>
        <van-field size="large" v-model="contact.houseNumber" :placeholder="$t('门牌号，格式：xxxx/xxxx')" clearable />
        <van-cell :title="contact.geo ? contact.geo.flashFull : $t('请选择省市区')" is-link @click="selectAddress" />
        <van-field size="large" autosize type="textarea" v-model="contact.address" :placeholder="$t('收件地址')" clearable />

        <van-checkbox v-model="contact.def" checked-color="#07c160" style="padding: 10px 15px">{{ $t("设为默认收件地址")
        }}</van-checkbox>
      </div>

      <div class="contactor__action" slot="footer">
        <van-button @click="doSubmit" type="primary" round :loading="submitLoading" :disabled="submitLoading" block>{{
          $t("确 定") }}</van-button>
      </div>
    </van-panel>

    <van-popup v-model="addressShow" position="bottom">
      <div style="height: 500px">
        <van-search v-model="keyWord" shape="round" show-action @search="onSearch" :placeholder="$t('乡/市/省/邮编')"
          use-action-slot>
          <van-button slot="action" type="default" size="small" :loading="searchLoading" :disabled="searchLoading" round
            @click="onSearch">
            {{ $t("搜索") }}
          </van-button>
        </van-search>

        <div v-for="item in addressList" :key="item.fid" style="padding: 10px 15px" class="van-hairline--bottom"
          @click="addressSelect(item)">
          {{ item.flashFull }}
        </div>

        <van-empty v-if="!addressList.length" image="search" :description="$t('暂无数据')" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Panel,
  Field,
  Button,
  Toast,
  Cell,
  Popup,
  Search,
  Empty,
  Checkbox,
} from "vant";

import {
  contactDetail,
  addContact,
  editContact,
  searchGeo,
} from "@/service/api";

const rules = {
  name: {
    required: true,
    maxlength: 30,
  },
  mobile: {
    required: true,
    minlength: 8,
    maxlength: 20,
  },
  address: {
    required: true,
    nochinese: true,
    maxlength: 300,
    minlength: 12,
  },
  houseNumber: {
    required: true,
    houseNumberFormat: true,
    maxlength: 20,
  },
  postalCode: {
    required: true,
    nochinese: true,
    maxlength: 5,
    minlength: 5,
  },
};

export default {
  components: {
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Panel.name]: Panel,
    [Field.name]: Field,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Search.name]: Search,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      submitLoading: false,
      searchLoading: false,
      contact: { def: 0 },
      id: this.$route.params.id,
      addressShow: false,
      keyWord: "",
      geo: {},
      addressList: [],
      errorMessages: {
        name: {
          required: this.$t("请输入姓名"),
          maxlength: this.$t("姓名最多输入30个字符"),
        },
        mobile: {
          required: this.$t("请输入电话"),
          minlength: this.$t("电话最少输入8个字符"),
          maxlength: this.$t("电话最多输入20个字符"),
        },
        houseNumber: {
          required: this.$t("请输入门牌号"),
          housenumberFormat: this.$t('门牌号需为数字，格式xxxx/xxxx，如：239/8'),
          maxlength: this.$t('门牌号最多输入20个字符')
        },
        address: {
          required: this.$t("请输入详细地址"),
          nochinese: this.$t("收货地址不能有汉字"),
          minlength: this.$t("地址最少输入12个字符"),
          maxlength: this.$t("地址最多输入300个字符"),
        },
        postalCode: {
          required: this.$t("请输入邮编"),
          nochinese: this.$t("邮编不能有汉字"),
          minlength: this.$t("邮编最少输入5个数字"),
          maxlength: this.$t("邮编最多输入5个数字"),
        },
      },
    };
  },
  mounted() {
    if (this.id) this.contactDetail();
    else {
      const { total } = this.$route.query;
      this.contact.def = total == 0 ? 1 : 0;
    }
  },
  methods: {
    addressSelect(item) {
      this.addressShow = false;
      this.contact.geo = item;
      this.contact.postalCode = item.postcode;
    },
    onSearch() {
      this.searchGeo();
    },
    async searchGeo() {
      this.searchLoading = true;
      const res = await searchGeo(this.keyWord);

      if (res.success) {
        this.addressList = res.data;
      } else Toast.fail(res.message);

      this.searchLoading = false;
    },
    selectAddress() {
      this.addressShow = true;
    },
    async contactDetail() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await contactDetail(this.id);

      this.contact = res;
      Toast.clear();
    },
    doReset() {
      this.contact = { def: 0 };
    },

    validate(content, rule, errorMsg) {
      if (!content || content.length === 0) {
        Toast(errorMsg.required);
        return false;
      }

      if (rule.nochinese) {
        if (/.*[\u4E00-\u9FA5]+.*$/.test(content)) {
          Toast(errorMsg.nochinese);
          return false;
        }
      }

      // if (rule.houseNumberFormat) {
      // if (!/^\d{1,4}\/\d{1,4}$/.test(content)) {
      //   Toast(errorMsg.housenumberFormat);
      //   return false;
      // }
      // }

      if (content.length < rule.minlength) {
        Toast(errorMsg.minlength);
        return false;
      }

      if (content.length > rule.maxlength) {
        Toast(errorMsg.maxlength);
        return false;
      }
      return true;
    },

    async doSubmit() {
      const name = this.contact.name;
      if (!this.validate(name, rules.name, this.errorMessages.name)) return;

      const mobile = this.contact.mobile;
      if (!this.validate(mobile, rules.mobile, this.errorMessages.mobile))
        return;

      const houseNumber = this.contact.houseNumber;
      console.log(houseNumber);
      if (!this.validate(houseNumber, rules.houseNumber, this.errorMessages.houseNumber))
        return;

      const address = this.contact.address;
      if (!this.validate(address, rules.address, this.errorMessages.address))
        return;

      if (!this.contact.geo || !this.contact.geo.fid) {
        Toast(this.$t("请选择省市区"));
        return;
      } else this.contact.geoCode = this.contact.geo.fid;

      const postalCode = this.contact.postalCode;
      if (
        !this.validate(
          postalCode,
          rules.postalCode,
          this.errorMessages.postalCode
        )
      )
        return;

      this.submitLoading = true;
      const opName = !this.contact.id ? this.$t("新增") : this.$t("修改");
      try {
        this.contact.def = this.contact.def ? 1 : 0;
        const parmas = { ...this.contact };
        if (parmas.geo) delete parmas.geo;
        const res = !this.contact.id
          ? await addContact(parmas)
          : await editContact(this.id, parmas);
        if (res.success) {
          Toast.success(opName + this.$t("地址成功"));
        } else {
          Toast.fail(opName + this.$t("地址失败"));
        }
        this.submitLoading = false;

        this.$router.go(-1);
      } catch (e) {
        Toast.fail(opName + this.$t("地址失败"));
        this.submitLoading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.contactor {
  padding: 10px;
  background-color: #ffffff;
}

.no-border {
  border-top-width: 100px !important;
}

.contactor__name-wrapper {
  display: flex;
}

.contactor__def {
  padding: 15px 15px;
}

.contactor__def-label {
  font-size: 14px;
}

.contactor__action {
  text-align: right;
}

.van-cell {
  align-items: center;
}

.province {
  font-size: 14px;
  padding: 10px 16px;
}

.contactor__name-wrapper {
  .van-cell {
    padding: 10px 0px;
  }
}
</style>