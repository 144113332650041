<template>
  <div class="help">
    <van-cell-group>
      <van-cell :title="$t('语言切换')" :value="getCurrentLanguage()" is-link @click="showLanguages()" />
      <van-cell :title="$t('使用说明')" @click="toWebPage(serviceUrl)" is-link />
      <van-cell :title="$t('时效与价格')" @click="toWebPage(categoryUrl)" is-link />
      <!-- <van-cell title="充值支付测试" url="../webpage/webpage?src=oa&url=https://life.yfwl.xyz/" is-link />     -->

      <van-cell :title="$t('运费计算器')" is-link :to="{ name: 'calculator' }" />
    </van-cell-group>

    <van-cell-group :title="$t('热门问题')">
      <van-cell :title="$t('什么不能寄？')" :label="$t(
        '证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。'
      )
        " />
      <van-cell :title="$t('丢件或损毁如何赔付？')" :label="$t(
        '未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。'
      )
        " />
      <van-cell :title="$t('如何付运费？')" :label="$t(
        '包裹到达中国转运仓后，系统将给您发送付款通知，您可以使用泰国银行手机 App 扫码支付。 付款后发往泰国并派送上门'
      )
        " />
      <van-cell :title="$t('是否有最低体积要求？')" :label="$t('最低消费0.2m³，少于0.2m³按照0.2m³计费。')" />
    </van-cell-group>
    <div style="height: 50px"></div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import { Cell, CellGroup, Toast, ActionSheet } from "vant";
import { setLocales } from "@/utils/lang";
import storage from "@/utils/storage";
import { STORAGE_KEYS } from "@/utils/constants";
import { fetchUserInfo } from "@/service/api.js";

import { EventBus } from "@/utils/event-bus.js";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      wechatId: "@549tcrhe",
      show: false,
      serviceUrl: "",
      userInfo: null,
      categoryUrl: "",
      actions: [
        { name: "English", value: "en-US" },
        { name: "ภาษาไทย", value: "th-TH" },
        // { name: "中文繁體", value: "zh-TW" },
      ],
    };
  },
  mounted() {
    this.generateCategoryUrl();

    EventBus.$on("openUrl", (msg) => {
      window.open(msg, "_blank");
    });
  },
  methods: {
    toWebPage(url) {
      window.open(url, "_blank");
      // window.location.replace(url);
    },
    showLanguages() {
      this.show = true;
    },
    onSelect(item) {
      this.show = false;
      setTimeout(() => {
        this.changeLanguage(item.value);
      }, 500);
    },
    changeLanguage(value) {
      setLocales(value);
    },
    generateCategoryUrl() {
      const userInfo = JSON.parse(storage.get(STORAGE_KEYS.userInfo))
      if (!userInfo)
        return

      const {
        logisticCompany: { phone, address, receiver },
      } = userInfo;

      const locale = localStorage.getItem("locale") || "th-TH";
      let serviceName = "services-en.html";
      let cateogryName = "cargo_category-en.html";
      switch (locale) {
        case "zh-TW":
          serviceName = "services-cn.html";
          cateogryName = "cargo_category-cn.html";
          break;
        case "th-TH":
          serviceName = "services-th.html";
          cateogryName = "cargo_category-th.html";
          break;
        default:
          serviceName = "services-en.html";
          cateogryName = "cargo_category-en.html";
          break;
      }

      this.serviceUrl =
        "/services/" +
        serviceName +
        "?phone=" +
        phone +
        "&address=" +
        encodeURIComponent(address) +
        "&receiver=" +
        receiver;

      this.categoryUrl = "/category/" + cateogryName;
    },
    getCurrentLanguage() {
      const locale = localStorage.getItem("locale") || "th-TH";
      switch (locale) {
        case "zh-TW":
          return "中文繁體";
        case "th-TH":
          return "ภาษาไทย";
        default:
          return "English";
      }
    },
  },
};
</script>
<style lang="less" scoped>
@backgroundColor: #f2f2f2;

.help {
  // position: fixed;
  // top: 0px;
  // bottom: 50px;
  // overflow: auto;
  // padding-bottom: 20px;
  background-color: @backgroundColor;
}

.van-action-sheet {
  margin-bottom: 50px;
}
</style>