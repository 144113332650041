import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locale } from 'vant'
import storage from "@/utils/storage";

import enUS from '@/locales/en-US'
import thTH from '@/locales/th-TH'
import zhTW from '@/locales/zh-TW'

Vue.use(VueI18n);

const messages = {
    'en-US': {
        ...enUS,
    },
    'th-TH': {
        ...thTH,
    },
    'zh-TW': {
        ...zhTW,
    }
}

const i18n = new VueI18n({
    locale: storage.get('locale') || 'th-TH',  // 设置默认语言
    messages: messages, // 设置资源文件对象
    silentTranslationWarn: true,
})

const setLocales = (lang) => {
    if (lang === 'en-US') {
        Locale.use(lang, enUS)
    } else if (lang === 'zh-TW') {
        Locale.use(lang, zhTW)
    } else {
        Locale.use(lang, thTH)
    }

    storage.set('locale', lang)
    window.location.reload()
}

export { i18n, setLocales }