export default {
    '没有更多了': 'End of list',
    '加载中...': 'Loading...',
    '复制成功': 'Copied',
    '确认': 'Confirm',
    '确定': 'Confirm',
    '取消': 'Cancel',
    '暂无数据': 'No data',
    '查件': 'Order check',
    '客服': 'Customer service',
    '我的': 'Profile',
    '搜索快递单号': 'Search for the tracking number',
    '搜索': 'Search',
    '发往泰国(total)': 'Ship to Thailand({total})',
    '发往泰国': 'Shipping to Thailand',
    '点击支付 >>': '{total} parcels arrived and ready to be delivered to your door. Click to pay >>',
    '暂无发往泰国运单': 'Temporary no waybills',
    '派送上门(total)': 'Delivering ({total})',
    '派送上门': 'Delivering',
    '暂无派送上门运单': 'No delivery waybill',
    '已签收': 'Signed',
    '件': 'Package',
    '暂无已签收运单': 'No waybill has been signed yet',
    '小蜜蜂转运优惠券': 'Tukship Coupon',
    '券': 'Coupon',
    '折': 'Discount',
    '无门槛': 'No conditions',
    '满可用': 'Available for {amount} baht',
    '领取后请到[我的-优惠劵]查看': 'Please go to [My-Discount Voucher] to view after receiving',
    '填写收件地址后可以领取优惠券': 'You can receive the coupon after filling in the receiving address',
    '一键领取': 'Grab it now!',
    '前往填写收件地址': 'Go to fill in your address',
    '您已成功领取': 'Coupon claimed',
    '领取失败,稍后再试': 'Failed to claim, try again later',
    '客服微信': 'Customer Service WeChat',
    '[点击复制]': '[Copy]',
    '语言切换': 'Language',
    '使用说明': 'How it works',
    '时效与价格': 'Lead time and pricing',
    '热门问题': 'Top Questions',
    '什么不能寄？': 'What can\'t be sent?',
    '证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。': 'Credentials such as ID cards, cigarettes, alcohol, flammable and explosive materials, hazardous chemicals, fresh products, plants and seeds, animals and other items that violate the laws of China and Thailand.',
    '丢件或损毁如何赔付？': 'How are we compensate for lost or damaged goods?',
    '未保价的包裹按运费三倍赔偿，保价后按保价金额赔偿，贵重物品请务必预报运单并保价。': 'Orders that are not insured will be compensated three times the freight rate, and after insuring, they will be compensated according to the insured amount. For valuable items, please be sure to choose the additional services to insure the goods.',
    '如何付运费？': 'How to pay for freight service?',
    '包裹到达泰国后，系统将给您发送运费通知，支付后系统安排配送包裹上门。': 'After the package arrives in Thailand, the system will send you a payment notification. After payment, the system will arrange the freight delivery to your door.',
    '包裹到达中国转运仓后，系统将给您发送付款通知，您可以使用泰国银行手机 App 扫码支付。 付款后发往泰国并派送上门': 'When the package arrives at the forwarding warehouse in China, the system will send you a payment notification, and you can use your bank\'s mobile app to scan the code to pay. After payment, the package will be shipped to Thailand and delivered to your door.',
    '是否有最低重量要求？': 'Is there a minimum weight requirement?',
    '是否有最低体积要求？': 'Is there a minimum volume requirement?',
    '最低消费0.2m³，少于0.2m³按照0.2m³计费。': 'The minimum order is 0.2 cbm. Orders that are smaller than 0.2 cbm will be charged a shipping fee of 0.2 cbm,which is {minimumFee} baht.',
    '单批包裹不满一公斤按一公斤计费，例如您有两个包裹到达泰国仓库，如果两个包裹的总重量不足一公斤, 按一公斤计费。如果您还有包裹在路上，先到的包裹可以暂存泰国仓，等到齐之后一起计费付款，再派送上门。': 'A single batch of parcels less than one kilogram will be charged as one kilogram. For example, if you have two parcels arriving at a Thai warehouse, if the total weight of the two parcels is less than one kilogram, they will be charged as one kilogram. If you still have parcels on the way, the first parcel can be temporarily stored in the Thai warehouse, and the bill will be charged and paid when all the parcels arrive before being delivered to your door.',
    '用户代码': 'User code',
    '泰国收件地址': 'Thailand Recipient',
    '点击填写收件地址': 'Click to fill in the recipient address',
    '预报运单': 'Additional services',
    '退回/保价/打木架/到付': 'Insurance/Wooden Frame/Pay on Delivery/Reject',
    '优惠券': 'Coupon',
    '转运仓信息': 'Recipient-on-behalf information',
    '收件人': 'Recipient',
    '电话': 'Mobile',
    '地址': 'Address',
    '[复制转运仓信息]': '[Copy information]',
    '张': 'piece',
    '无可用券': 'No coupons available',
    '请您先填写泰国收件地址': 'Please fill in the local delivery address',
    '单号': 'Tracking number',
    '[编辑备注]': 'Edit notes',
    '[复制单号]': 'Copy tracking number',
    '增值服务': 'Value-added services',
    '共铢(不含到付)': 'a total of {amount} baht (not including payment on delivery)',
    '等待包裹入库': 'Waiting for the parcel to arrive at GZ warehouse',
    '重量': 'Weight',
    'weight 公斤': '{weight} kg',
    '长宽高(单位公分)': 'Length, width, height (cm)',
    '体积': 'Volume',
    '运输方式': 'Transport type',
    '计费方式': 'Billing method',
    '折扣': 'Discount',
    '转运费': 'Freight Fee',
    '尾程运费': 'Second Leg Fee',
    '铢': 'Baht',
    '共铢(不含到付收费)': 'A total of {amount} baht (not including payment on delivery)',
    '收件地址': 'Address',
    '登记单号，选择您需要的增值服务': 'Registration tracking number, select the value-added service you need',
    '运单号': 'Tracking number',
    '请输入运单号': 'Please enter tracking number',
    '陆运': 'Land',
    '海运': 'Sea',
    '海运不满30KG或0.2立方，按30KG收费': 'Sea freight of less than 30 kg or 0.2 cbm will be automatically charged at 30 kg',
    '保价': 'Insurance',
    '保价金额，保费': 'Insured amount:{declareAmount}，Insurance premium:{insuranceFee}',
    '未保价运单按运费三倍赔偿': 'Please be noted that the uninsured orders will be compensated at three times the freight',
    '打木架': 'Wooden frame',
    '到付快递费': 'Payment on delivery',
    '按中国仓实付金额收费': 'The actual amount paid by China warehouse will be charged accordingly',
    '中国仓退回': 'Notice warehouse to reject this item',
    '备注': 'Remarks',
    '请输入备注': 'Please enter remarks',
    '我已阅读并同意': 'I have read and agree',
    '《服务条款》': 'Terms of Service',
    '请填写运单号': 'Please enter tracking number',
    '单号最多输入60个字符': 'Enter a maximum of 60 characters for the tracking number',
    '单号只能输入字母、数字和横线,长度不超过60': 'The single number can only enter letters, numbers and horizontal lines, and the length does not exceed 60',
    '请选择运输方式': 'Please select transport type',
    '请先阅读并同意服务条款': 'Please read and agree to the terms of service first',
    '登记成功': 'Register success',
    'count件包裹待支付': '{count} parcel to be paid',
    '首重计费': 'First billing',
    '折扣立减': 'Discount',
    '请选择优惠券': 'Please select coupon',
    '无可用优惠券': 'No coupons available',
    '实付': 'Total',
    '支付': 'Pay',
    '汇率': 'Exchange rate',
    '剩余': 'Balance',
    '取消用券': 'Cancel coupon',
    '我们将尽快为您配送上门，感谢您的支持。': 'We will deliver to your door as soon as possible, thank you for your support.',
    '如有问题请联系客服微信': 'If you have any questions, feel free to contact our customer service via Line Official',
    '[复制]': '[Copy]',
    '前往查件': 'Back to check parcels',
    '下单成功': 'Successfully ordered',
    '请以英文或泰文填写收件人姓名和地址': 'Please fill in the recipient\'s name and address in English or Thai',
    '姓名': 'Name',
    '邮编': 'Postcode',
    '确 定': 'Confirm',
    '清 空': 'Clear',
    '请输入姓名': 'Please enter your name',
    '姓名最多输入30个字符': 'Enter up to 30 characters for your name',
    '请输入电话': 'Please enter mobile',
    '电话最少输入8个字符': 'Enter at least 8 characters for the phone',
    '电话最多输入20个字符': 'Enter up to 20 characters for the phone',
    '请输入详细地址': 'Please enter the detailed address',
    '收货地址不能有汉字': 'The delivery address cannot have Chinese characters',
    '地址最少输入12个字符': 'Enter at least 12 characters for the address',
    '地址最多输入300个字符': 'Enter up to 300 characters for the address',
    '请输入邮编': 'Please enter postcode',
    '邮编不能有汉字': 'Postcode cannot have Chinese characters',
    '邮编最少输入5个数字': 'Enter at least 5 digits for the postcode',
    '邮编最多输入5个数字': 'Enter up to 5 digits for the postcode',
    '新增': 'Added',
    '修改': 'Edit',
    '地址成功': 'Added new address',
    '地址失败': 'Wrong address',
    '月月发券，四季常有': 'Coupons issued monthly, available in year round',
    '每月1号开始可领取月度优惠券': 'Grab your coupons from the 1st of every month',
    '可用券': 'Available coupons',
    '有效期至': 'Valid until',
    '已过期': 'Expired',
    '使用日期': 'Date of usage',
    '已用券': 'Used coupons',
    '保价金额': 'Insured amount',
    '请输入人民币金额': 'Please enter the THB amount',
    '保费': 'Insurance',
    '按保价金额的%自动计算': 'Automatically calculated at {rate}% of the insured amount',
    '取消保险': 'Cancel insurance',
    '最高保价金额人民币': 'Maximum insured amount is {amount} THB',
    '快递单号': 'Tracking number',
    '共count件包裹': 'A total of {count} packages',
    '收件信息': 'Received',
    '备注最多不超过15个字符': 'Remark cannot exceed 15 characters',
    '编辑成功': 'Editted',
    '暂时没有找到相关运单': 'No waybill found',
    '请输入要搜索的快递单号': 'Please enter the tracking number',
    '包裹到达泰国后将通知您支付转运费并配送上门。': 'After the parcel arrives in Bangkok, you will be notified to pay the freight.',
    '继续预报运单': 'Select more additional services',
    '待支付': 'To be paid',
    '已支付': 'Paid',
    '等待入仓': 'Waiting for entry',
    '到达中转仓': 'Received at warehouse',
    '泰国仓分拣中': 'Sorting',
    '您已签收': 'Signed',
    '安排配送中': 'Arrangement for delivery',
    '待打包': 'Ready to pack',
    '已打包': 'Packed',
    '问题件': 'Questionable parcel',
    '按重量': 'By weight',
    '按体积': 'By volume',
    '请转账至': 'Please transfer to',
    '银行': 'Bank',
    '账号': 'Account',
    '户名': 'Name',
    '上传网银截图': 'Upload payment slip',
    '金额': 'Amount',
    '请输入充值金额': 'Please enter the top up amount',
    '账户余额': 'Balance',
    '充值': 'Top Up',
    '用户余额充值': 'Top Up',
    '运费返还': 'Freight return',
    '运单赔付': 'Waybill compensation',
    '待审核': 'Waiting for confirmation',
    '审核不通过': 'Confirmation failed',
    '暂无记录': 'No data',
    '余额': 'Balance',
    '设为默认收件地址': 'Set as default',
    '编辑': 'Edit',
    '删除': 'Delete',
    '新增地址': 'Add address',
    '请选择收货地址': 'Please select the delivery address',
    '配送方式': 'Delivery method',
    '请选择配送方式': 'Please choose a delivery method',
    '余额不足': 'Insufficient balance',
    '登录中...': 'Logging in ...',
    '小蜜蜂物流': 'Xiaomifeng express',
    '申请': 'Apply',
    '获取你的昵称、头像': 'Confirm your nickname and profile picture',
    'Line个人信息': 'Line personal information',
    '允许': 'Permit',
    '未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。': 'For the safety of the fragile items, we recommend our customers to add wooden crates to prevent damages during shipping',
    '如何消费？>>': 'How to pay?',
    '本地派送预付费用': 'Local delivery fee',
    '的红包': 's coupon',
    '一起来分享': 'Let\'s share',
    '已存入您的小蜜蜂账户余额，可直接消费': 'Credits topped up, you can make payment now',
    '您来迟了,红包已被抢光了!': 'Sorry, you\'re late. Coupon is finished.',
    'count个红包已被领取': '{count} coupons have been picked',
    '乡/市/省/邮编': 'Sub-district, district, province, postal code',
    '请选择省市区': 'Please choose your district, city and postal code',
    '保险': 'Insurance',
    '国内快递费到付': 'COD in China',
    '运费计算器': 'Freight calculator',
    '尺寸': 'Measurement',
    '长': 'Length',
    '宽': 'Width',
    '高': 'Height',
    '请输入重量': 'Please input weight',
    '国际运费': 'International freight',
    '本地运费': 'Second Leg Fee',
    '运费': 'Freight',
    '计算': 'Calculate',
    '请输入长度': 'Please enter the length',
    '请输入宽度': 'Please enter the width',
    '请输入高度': 'Please enter the height',
    '费用': 'Total',
    '货物类别': 'Types of goods',
    '重置': 'Reset',
    '计算结果': 'Grand total',
    '打木架费': 'Wooden frame assemble',
    'A类': 'Type A',
    'B类': 'Type B',
    'C类': 'Type C',
    '单位': 'Unit',
    '查看价格表': 'Check the price list',
    '选填': 'Optional',
    '必填': 'Necessary',
    '预估费用': 'Approximate cost',
    '下拉即可刷新...': 'Slide down to refresh',
    '释放即可刷新...': 'Release to refresh',
    '地址管理': 'Address management',
    '地址簿': 'Address book',
    '运单详情': 'Waybill information',
    '保险服务': 'Goods insurance',
    '我的优惠券': 'My coupons',

    '我的余额': 'My credit',
    '小蜜蜂红包': 'Tukship\'s cash coupons',
    '默认': 'Set as primary',
    '选为收件地址': 'Selected as recipient address',
    '没有': 'None',
    '恭喜获得amount泰铢红包': 'Congrats! You received {amount} THB cash coupon',
    '分享给小伙伴，大家一起抢': '',
    '分享到Line领取': 'Share to LINE to receive',
    '下次再说': 'Skip this time',
    '自提': 'Self pick-up',
    '小蜜蜂': 'Maximus',
    'JD': 'JD',
    'Deliveree': 'Deliveree',
    '您已经登记此包裹，可在「查件」页面搜索单号': 'This parcel has been registered into the system, please go to [Order Check] to search',
    '中转仓支付后发出。点击支付 >>': '{total} parcels arrived and ready to be delivered to your door. Click to pay >>',
    '待付款': 'Pending payment',
    '暂无待付款运单': 'No waybill',

    '已揽收': 'picked up by local express',
    '已退回泰国仓': 'returned to Thailand warehouse',
    '出库中': 'preparing for shipping',

    '暂不领取': 'Remind me later',
    '付陆运包裹费用': '付陆运包裹费用',
    '付海运包裹费用': '付海运包裹费用',
    '陆运海运包裹统计': 'You have {totalByLand} parcels shipped by land and {totalBySea} parcels shipped by sea',
    '正在计算增值服务费用': 'The cost of value-added services is being calculated',
    '审核中': 'Reviewing',
    '昵称': 'Nick name',
    '支付有余额': '共需支付{totalDiscountedForeignFee}铢，其中余额支付{balance}铢{cashPayAmount}，支付后发往泰国，如需留仓集包，可等包裹到齐再付。',
    '支付无余额': '共需支付{totalDiscountedForeignFee}铢，其中扫码支付{totalDiscountedForeignFee}铢，支付后发往泰国，如需留仓集包，可等包裹到齐再付。',
    '留仓集包提示': 'You can choose to wait to pay until all of your packages arrive at our China Warehouse.',
    '扫码支付': 'Scan QR to pay',
    'Please use PromptPay to scan this QRCode': 'Please use PromptPay to scan this QRCode',
    'DO NOT pay more than once!': 'DO NOT pay more than once!',
    '1. Screenshot this OR Code.': '1. Screenshot this OR Code.',
    '2. Open mobile banking app on your pohone.': '2. Open mobile banking app on your pohone.',
    "3. Select 'Scan' or 'QR code', then choose 'Open Picture'": "3. Select 'Scan' or QR code', then choose 'Open Picture'",
    "4. Select the QR Code you have received and pay": '4. Select the QR Code you have received and pay',
    '余额支付': 'Pay with the Tukship wallet',
    '充值提示': 'Please top up',
    '前往充值': 'Top up',
    '前往充值提示': 'Your account has a balance of {balance} baht and a total of {totalDiscountedForeignFee} baht to be paid. Please go to top up',

    '普货': 'A class products',
    '敏感货': 'B class products',
    '特殊货': 'C class products',
    '拒收': 'Reject',
    '退回': 'Return',
    '加固包装': 'Reinforced packaging',
    '支付运费': 'Pay Freight',
    '红包': 'Gift money',
    '提现': 'Withdraw',
    '赔付': 'Compensation',
    '未通过,请联系客服': 'Not approved, please contact customer service',
    '交易成功': 'Successful transaction',
    '微信': 'Wechat',
    '支付宝': 'Alipay',
    '转账': 'Bank Transfer',
    '领取': 'Claim',
    '到达泰国仓': 'Arrived at Thailand warehouse',
    '已取消': 'Cancelled',
    '司机': 'Staff',
    '交易记录': 'Transaction',
    '最低0.2立方米计费': '最低0.2立方米计费',
    '门牌号，格式：xxxx/xxxx': '门牌号，格式：xxxx/xxxx',
    '门牌号需为数字，格式xxxx/xxxx，如：239/8': '门牌号需为数字，格式xxxx/xxxx，如：239/8',
    '门牌号最多输入20个字符': '门牌号最多输入20个字符',
    '请输入门牌号': '请输入门牌号',
    '支付未成功，请确认已付款': 'Payment not received yet,  please make sure you have paid and click again',
    '完成支付': 'Payment completed',
}