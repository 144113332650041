<template>
  <div class="index">
    <div v-if="notice && notice.content">
      <div v-if="notice.openType == 1" @click="toWebPage(notice.url)">
        <van-notice-bar wrapable :scrollable="false" :text="notice.content" />
      </div>
      <div v-else-if="notice.openType == 2">
        <van-notice-bar wrapable :scrollable="false" :text="notice.content" />
      </div>
      <van-notice-bar v-else wrapable :scrollable="false" :text="notice.content" />
    </div>

    <div class="swiper" v-if="swiper && swiper.items && swiper.items.length">
      <van-swipe :autoplay="3000" style="height: 120px">
        <div v-for="item in swiper.items" :key="item.id">
          <van-swipe-item>
            <div class="swiper-item" @click="handleSwiperTab(item)">
              <van-image style="width: 100%" fit="cover" :src="item.content"></van-image>
            </div>
          </van-swipe-item>
        </div>
      </van-swipe>
    </div>

    <div v-if="toBePay && toBePay.total" class="notice-message" @click="toBepayOrder">
      {{ $t("点击支付 >>", { total: toBePay ? toBePay.total : 0 }) }}
    </div>

    <van-search v-model="trackingNo" shape="round" show-action @search="onSearch" :placeholder="$t('搜索快递单号')"
      use-action-slot>
      <van-button slot="action" type="default" size="small" round @click="onSearch">
        {{ $t("搜索") }}
      </van-button>
    </van-search>

    <van-tabs v-model="active" color="#40c057" title-active-color="#40c057" title-inactive-color="#333"
      @change="changeTab" sticky>
      <van-tab :title="unpayCargoCount
        ? `${$t('待付款')}(${unpayCargoCount})`
        : $t('待付款')
        ">
        <van-pull-refresh v-model="toPayParams.refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
          :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
          <van-list v-model="toPayParams.loading" :finished="toPayParams.finished" :immediate-check="false" :style="{
            'min-height': toThailandLocalDeliveries.length ? '60vh' : '0px',
            'margin-bottom': '50px',
          }" :finished-text="cargos.length ? $t('没有更多了') : ''" :offset="50" @load="onLoad">
            <div class="cargo-list-item" v-for="item in cargos" :key="item.id" @click="toCargoDetail(item)">
              <div v-if="item.label" style="display: inline-block; width: 125px; height: 75px">
                <van-image width="125" height="75" radius="5px" fit="cover" :src="item.label" />
              </div>
              <div v-else style="display: inline-block; width: 125px; height: 75px">
                <van-image width="125" height="75" radius="5px" fit="contain"
                  :src="require('@/assets/icons/send-inactive.png')" />
              </div>
              <div class="cargo-desc">
                <div class="cargo-desc-title">{{ item.trackingNo }}</div>
                <div class="secondary-text">
                  {{ formatTime(item) }}
                  {{ $t(cargoStatus(item)) }}
                </div>
                <div class="cargo-desc-remark">
                  {{ item.remark ? item.remark : "" }}
                </div>
              </div>
              <van-icon name="arrow" />
            </div>
          </van-list>

          <van-empty v-if="!cargos.length" image="search" :style="{
            'min-height': '80vh',
          }" :description="$t('暂无待付款运单')" />
        </van-pull-refresh>
      </van-tab>
      <!--发往泰国tab-->
      <van-tab :title="toThailandDeliveryCount
        ? $t('发往泰国(total)', { total: toThailandDeliveryCount })
        : $t('发往泰国')
        ">
        <van-pull-refresh v-model="cargoParams.refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
          :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
          <van-list v-model="cargoParams.loading" :finished="cargoParams.finished" :immediate-check="false" :style="{
            'min-height': toThailandLocalDeliveries.length ? '60vh' : '0px',
            'margin-bottom': '50px',
          }" :finished-text="toThailandLocalDeliveries.length ? $t('没有更多了') : ''" :offset="50" @load="onLoad">
            <div class="deliver-to-door-list-item" v-for="item in toThailandLocalDeliveries" :key="item.id"
              @click="toOrderDetails(item)">
              <div class="deliver-to-door-list-item-title">
                <div v-if="item.fExpressCompany">{{ $t(convertFExpressCompany(item)) }}:
                  {{ formatFTrackingNo(item.fExpressCompany, item.fTrackingNo) }}
                </div>
                <div v-else>{{ $t(convertFExpressCompany(item.fExpressCompany)) }}</div>
                <div>{{ item.cargoList.length }}{{ $t('件') }}</div>
              </div>
              <div v-for="cargo in item.cargoList" :key="cargo.id">
                <van-cell :title="cargo.trackingNo" :value="cargo.remark ? cargo.remark : ''" />
              </div>
            </div>
          </van-list>

          <van-empty v-if="!toThailandLocalDeliveries.length" image="search" :style="{
            'min-height': '80vh',
          }" :description="$t('暂无发往泰国运单')" />
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="onDeliveryCount
        ? $t('派送上门(total)', { total: onDeliveryCount })
        : $t('派送上门')
        ">
        <van-pull-refresh v-model="deliveryParams.refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
          :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
          <van-list v-model="deliveryParams.loading" :finished="deliveryParams.finished" :immediate-check="false" :style="{
            'min-height': toThailandLocalDeliveries.length ? '60vh' : '0px',
            'margin-bottom': '50px',
          }" :finished-text="deliveries.length ? $t('没有更多了') : ''" @load="onLoad">
            <div v-for="item in deliveries" :key="item.id" @click="toOrderDetails(item)">
              <div class="deliver-to-door-list-item">
                <div class="deliver-to-door-list-item-title">
                  <div>
                    {{ $t(convertFExpressCompany(item)) }}:
                    {{ item.fTrackingNo }}
                  </div>
                  <div style="text-align: right; width: 130px">
                    {{ item.cargoList.length }}{{ $t("件") }}
                  </div>
                </div>

                <div v-for="cargo in item.cargoList" :key="cargo.id">
                  <van-cell :title="cargo.trackingNo" :value="cargo.remark ? cargo.remark : ''" />
                </div>
              </div>
            </div>
          </van-list>

          <van-empty v-if="!deliveries.length" image="search" :style="{
            'min-height': '80vh',
          }" :description="$t('暂无派送上门运单')" />
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="$t('已签收')">
        <van-pull-refresh v-model="signedParams.refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
          :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
          <van-list v-model="signedParams.loading" :finished="signedParams.finished" :immediate-check="false" :style="{
            'min-height': toThailandLocalDeliveries.length ? '60vh' : '0px',
            'margin-bottom': '50px',
          }" :finished-text="signedList.length ? $t('没有更多了') : ''" @load="onLoad">
            <div v-for="item in signedList" :key="item.id" @click="toOrderDetails(item)"
              class="deliver-to-door-list-item">
              <div class="deliver-to-door-list-item-title">
                <div>
                  {{ $t(convertFExpressCompany(item)) }}:
                  {{ item.fTrackingNo }}
                </div>
                <div style="text-align: right; width: 130px">
                  {{ item.cargoList.length }}{{ $t("件") }}
                </div>
              </div>

              <div v-for="cargo in item.cargoList" :key="cargo.id">
                <van-cell :title="cargo.trackingNo" :value="cargo.remark ? cargo.remark : ''" />
              </div>
            </div>
          </van-list>

          <van-empty v-if="!signedList.length" image="search" :style="{
            'min-height': '80vh',
          }" :description="$t('暂无已签收运单')" />
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

    <van-dialog use-slot v-model="showCouponDialog" :showConfirmButton="false" width="277px">
      <div class="coupon_header">
        <van-image class="header_img" :src="require('@/assets/images/dikuang_shang.png')" />
        <div class="coupon_header_title">{{ $t("小蜜蜂转运优惠券") }}</div>
      </div>
      <div class="coupon_content">
        <div class="coupon" v-for="item in couponList" :key="item.id">
          <van-image class="coupon_img" :src="require('@/assets/images/youhui.png')" />
          <div class="coupon_flag">券</div>
          <div v-if="item.type == 1" class="coupon_amount">
            <span class="coupon_amount_currency">฿</span>
            <span class="coupon_amount_num">{{
              item.value * item.claimMaximum
            }}</span>
            <!-- <div class="coupon_amount_desc">{{item.claimMaximum}} 张</div> -->
          </div>
          <div v-else-if="item.type == 2" class="coupon_amount">
            <span class="coupon_amount_num">{{ item.value * 10 }}</span>
            <span class="coupon_amount_currency">{{ $t("折") }}</span>
          </div>
          <div class="coupon_desc">
            <div class="coupon_desc_num">{{ item.title }}</div>
            <div class="coupon_desc_usage">
              {{
                item.minimumAmount == 0
                ? $t("无门槛")
                : $t("满可用", { amount: item.minimumAmount })
              }}
            </div>
            <!-- <div class="coupon_desc_usage">每次限领{{item.claimMaximum}}张</div> -->
          </div>
        </div>
      </div>
      <div class="coupon_footer">
        <div class="coupon_footer_tip">
          {{
            contactSize
            ? $t("领取后请到[我的-优惠劵]查看")
            : $t("填写收件地址后可以领取优惠券")
          }}
        </div>

        <!-- <van-button v-if="contactSize" block round style="color: #da3f3a" type="primary" color="#FCDF82"
          :loading="claimLoading" @click="doClaim">
          {{ $t("一键领取") }}
        </van-button> -->
        <div v-if="contactSize">
          <van-row>
            <van-col span="10" class="notClaim">
              <van-button round plain @click="hideCouponDialog"> {{ $t("暂不领取") }}</van-button>
            </van-col>
            <van-col span="14" class="doClaim">
              <van-button block round type="primary" color="#FCDF82" style="border: none;color: #da3f3a"
                :loading="claimLoading" @click="doClaim">
                {{ $t("一键领取") }}
              </van-button>
            </van-col>
          </van-row>
        </div>
        <van-button v-else block round style="color: #da3f3a" type="primary" color="#FCDF82" @click="goContact">
          {{ $t("前往填写收件地址") }}
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  Search,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  List,
  PullRefresh,
  Image as VanImage,
  Icon,
  Empty,
  NoticeBar,
  Swipe,
  SwipeItem,
  Dialog,
  Toast,
  Col, Row
} from "vant";

import {
  toBePay as cargoToBePay,
  cargoList,
  localdelivery,
  countByStatus,
  getNotice,
  getClaimableCouponList,
  claim,
  groupCount
} from "@/service/api";
import { formatCargoStatusTime, formatFTrackingNo } from "@/utils/utils";
import {
  convertCargoStatus,
  convertFExpressCompany,
  convertLocalDeliveryStatus,
} from "@/utils/dict";

import { EventBus } from "@/utils/event-bus.js";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Row.name]: Row,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    "van-dialog": Dialog.Component,
  },
  data() {
    return {
      trackingNo: "",
      active: 0,
      toBePay: null,
      notice: {},
      swiper: {},
      cargos: [],
      toThailandLocalDeliveries: [],
      deliveries: [],
      signedList: [],
      contactSize: 0,
      couponList: [],
      showCouponDialog: false,
      claimLoading: false,
      toPayParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
      cargoParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
      deliveryParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
      signedParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
      unpayCargoCount: 0,
      toThailandDeliveryCount: 0,
      onDeliveryCount: 0
    };
  },
  mounted() {
    this.queryNotice();
    this.queryClaimableCoupon();
  },
  activated() {
    this.cargoToBePay();
    this.onRefresh();
    this.groupCount()
  },
  methods: {
    hideCouponDialog() {
      this.showCouponDialog = false
    },
    onSearch() {
      this.$router.push({
        name: "searchResult",
        query: {
          trackingNo: this.trackingNo,
        },
      });
    },
    toWebPage(url) {
      window.open(url, "_blank");
    },
    async groupCount() {
      const res = await groupCount()
      const { success, data } = res
      if (success) {
        const { toThailandDeliveryCount, unpayCargoCount, onDeliveryCount } = data
        this.toThailandDeliveryCount = toThailandDeliveryCount
        this.unpayCargoCount = unpayCargoCount
        this.onDeliveryCount = onDeliveryCount
      }
    },
    handleSwiperTab(item) {
      if (item.openType == 1) {
        const url = item.url
        if (url.startsWith('http')) {
          window.open(url, "_blank");
        } else {
          this.$router.push({
            path: url
          })
        }
      }
    },
    formatTime(item) {
      return formatCargoStatusTime(item);
    },
    cargoStatus(item) {
      return convertCargoStatus(item.status, item.paymentStatus);
    },
    convertFExpressCompany(item) {
      return convertFExpressCompany(item.fExpressCompany);
    },
    convertLocalDeliveryStatus(item) {
      return convertLocalDeliveryStatus(item.status);
    },
    formatFTrackingNo(fExpressCompany, fTrackingNo) {
      return formatFTrackingNo(fExpressCompany, fTrackingNo)
    },
    goContact() {
      this.$router.push({ name: "Contact" });
    },
    toBepayOrder() {
      if (!this.toBePay)
        return
      const { v3, totalBySea, totalByLand } = this.toBePay
      // if (totalByLand && totalBySea) {
      //   this.$router.push({
      //     name: "paychoice",
      //     query: {
      //       v3, totalBySea, totalByLand
      //     },
      //   });
      // } else
      // const expressWay = totalByLand ? 1 : (totalBySea ? 2 : 1)
      EventBus.$emit("order-reset", {});
      this.$router.push({
        name: "order",
        query: {
          v3,
          // expressWay
        },
      });
    },
    sendMsg() {
      EventBus.$emit("coupon", "");
    },
    async doClaim() {
      this.claimLoading = true;
      if (this.couponList.length) {
        const couponIds = this.couponList.map((coupon) => coupon.id);
        try {
          const res = await claim(couponIds);
          if (res.success) {
            Toast.success(this.$t("您已成功领取"));
            this.sendMsg();
          } else {
            Toast.fail(res.message);
          }
        } catch (e) {
          Toast.fail(this.$t("领取失败,稍后再试"));
        }
      }
      this.claimLoading = false;
      this.showCouponDialog = false;
    },
    toCargoDetail(item) {
      this.$router.push({ name: "cargoDetail", query: { id: item.id } });
    },
    toOrderDetails(item) {
      this.$router.push({ name: "orderDetails", query: { id: item.id } });
    },
    async queryNotice() {
      const res = await getNotice();
      if (res && res.data && res.data.length) {
        const noticeList = res.data.filter((item) => item.displayMode == 1);
        const bannerList = res.data.filter((item) => item.displayMode == 2);
        const swiper = bannerList && bannerList.length ? bannerList[0] : null;
        const notice = noticeList && noticeList.length ? noticeList[0] : null;
        this.notice = notice;
        this.swiper = swiper;
      }
    },
    async cargoToBePay() {
      const res = await cargoToBePay();
      if (res && res.total) {
        this.toBePay = res;
      } else {
        this.toBePay = { total: 0 };
      }
    },
    async queryClaimableCoupon() {
      const res = await getClaimableCouponList();
      if (res.success) {
        const {
          data,
          extra: { contactSize = 0 },
        } = res;
        this.contactSize = contactSize; //是否填写了地址
        this.couponList = data;
        this.showCouponDialog = data && data.length > 0;
      }
    },
    // 待付款接口
    async cargoList() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      let params = {
        currentPage: this.toPayParams.page,
        pageSize: 10,
        unpay: true,
        sorter: "id_desc",
      };

      const res = await cargoList(params);
      Toast.clear();

      if (res.success) {
        const { data, total } = res;
        if (this.toPayParams.page === 1) this.cargos = [];
        this.cargos = this.cargos.concat(data);

        this.toPayParams.finished = this.cargos.length >= total;
      } else
        this.cargoParams.finished = true

      this.toPayParams.refreshing = false;
      this.toPayParams.loading = false;
    },
    // 发往泰国接口
    async toThailand() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      let params = {
        pageSize: 10,
        currentPage: this.cargoParams.page,
        statusAry: '0,1,4,6',
        sorter: 'createdAt_desc'
      };

      const res = await localdelivery(params);
      Toast.clear();

      this.cargoParams.refreshing = false;
      this.cargoParams.loading = false;

      if (res.success) {
        const { data, total } = res;
        if (this.cargoParams.page === 1) this.toThailandLocalDeliveries = [];
        this.toThailandLocalDeliveries = this.toThailandLocalDeliveries.concat(data);
        this.cargoParams.finished = this.toThailandLocalDeliveries.length >= total;
      } else
        this.cargoParams.finished = true
    },
    //派送上门
    async shipToDoor() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      let params = {
        pageSize: 10,
        currentPage: this.deliveryParams.page,
        statusAry: '2,8,10'
      };

      const res = await localdelivery(params);
      Toast.clear();

      this.deliveryParams.refreshing = false;
      this.deliveryParams.loading = false;

      if (res.success) {
        const { data, total } = res;
        if (this.deliveryParams.page === 1) this.deliveries = [];
        this.deliveries = this.deliveries.concat(data);
        this.deliveryParams.finished = this.deliveries.length >= total;
      } else this.deliveryParams.finished = true;
    },
    // 已签收
    async signedCargoList() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const params = {
        currentPage: this.deliveryParams.page,
        pageSize: 10,
        status: 3,
        sorter: 'custReceivedAt_desc'
      };

      const res = await localdelivery(params);
      Toast.clear();

      this.signedParams.refreshing = false;
      this.signedParams.loading = false;

      if (res.success) {
        const { data, total } = res;
        if (this.signedParams.page === 1) this.signedList = [];
        this.signedList = this.signedList.concat(data);
        this.signedParams.finished = this.signedList.length >= total;
      } else this.signedParams.finished = true;
    },

    onRefresh() {
      switch (this.active) {
        case 0:
          this.toPayParams.refreshing = true;
          this.toPayParams.page = 1;
          this.cargoList();
          break;
        case 1:
          this.cargoParams.refreshing = true;
          this.cargoParams.page = 1;
          this.toThailand();
          break;
        case 2:
          this.deliveryParams.refreshing = true;
          this.deliveryParams.page = 1;
          this.shipToDoor();
          break;
        case 3:
          this.signedParams.refreshing = true;
          this.signedParams.page = 1;
          this.signedCargoList();
          break;
      }
    },
    onLoad() {
      switch (this.active) {
        case 0:
          this.toPayParams.page = this.toPayParams.page + 1;
          this.cargoList();
          break;
        case 1:
          this.cargoParams.page = this.cargoParams.page + 1;
          this.toThailand();
          break;
        case 2:
          this.deliveryParams.page = this.deliveryParams.page + 1;
          this.shipToDoor();
          break;
        case 3:
          this.signedParams.page = this.signedParams.page + 1;
          this.signedCargoList();
          break;
      }
    },

    changeTab(index) {
      switch (index) {
        case 0:
          this.toPayParams.page = 1;
          this.cargoList();
          break;
        case 1:
          this.cargoParams.page = 1;
          this.toThailand();
          break;
        case 2:
          this.deliveryParams.page = 1;
          this.shipToDoor();
          break;
        case 3:
          this.signedParams.page = 1;
          this.signedCargoList();
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@backgroundColor: #f2f2f2;

.index {
  text-align: center;
  background-color: @backgroundColor;
}

.cargo-list-item {
  display: flex;
  background-color: #fff;
  padding: 20px;
  margin-top: 5px;
  align-items: center;
  border-radius: 5px;
}

.van-search__action {
  line-height: normal !important;
}

.cargo-list-item .cargo-desc {
  text-align: left;
  flex-grow: 2;
  line-height: 25px;
  margin-left: 10px;
}

.cargo-list-item .cargo-desc .cargo-desc-title {
  font-size: 1.1rem;
}

.cargo-desc-remark {
  margin-top: 5px;
}

.deliver-to-door-list-item {
  border-radius: 5px;
  margin: 10px;
}

.deliver-to-door-list-item-title {
  background-color: #f8f9fa;
  display: flex;
  padding: 8px 10px;
  color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
}

.van-cell__title {
  text-align: left;
}

.swiper {
  background-color: #fff;
  margin-bottom: 5px;
}

.swiper-item {
  background-color: #fff;
}

.coupon_header {
  margin-bottom: 10px;
}

.coupon_header_title {
  text-align: center;
  font-size: 16px;
  color: #da3f3a;
}

.header_img {
  width: 277px;
  height: 35px;
  /* border: 1px solid red; */
}

.coupon_content {
  /* max-height: 190px; */
  /* overflow-y: scroll; */
  /* border:1px solid red; */
}

.coupon {
  width: 197px;
  height: 89px;
  margin: 0px auto 5px auto;
  position: relative;
}

.coupon:last-child {
  margin-bottom: 0px;
}

.coupon_img {
  width: 197px;
  height: 89px;
}

.coupon_flag {
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.coupon_amount {
  color: #fff;
  position: absolute;
  left: 0px;
  top: 30px;
  width: 77px;
  /* border: 1px solid red; */
  text-align: center;
}

.coupon_amount_currency {
  font-size: 12px;
  margin-right: 3px;
}

.coupon_amount_num {
  font-size: 24px;
}

.coupon_amount_desc {
  font-size: 14px;
}

.coupon_desc {
  position: absolute;
  width: 100px;
  left: 77px;
  top: 20px;
  padding: 5px 10px;
  border-left: 1px dashed #fff;
  font-size: 16px;
  color: #fff;
  /* border: 1px solid red; */
}

.coupon_desc_usage {
  margin-top: 5px;
  font-size: 14px;
}

.coupon_footer {
  padding: 10px 15px;
}

.coupon_footer_tip {
  color: #b6b6b6;
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
}

.deliver-to-door-list-item-title {
  background-color: #f8f9fa;
  display: flex;
  padding: 8px 10px;
  color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
}

::v-deep .van-tab__text--ellipsis {
  -webkit-line-clamp: 2;
}

.doClaim {
  ::v-deep .van-button__text {
    color: #da3f3a;
  }
}

.notClaim {
  ::v-deep .van-button--default {
    border: none;
  }
}
</style>