<template>
  <div class="home">
    <Index v-show="active == 0" />
    <Help v-show="active == 1" />
    <UserCenter v-show="active == 2" />
    <van-tabbar v-model="active" @change="tabChange" active-color="#FDB02A" inactive-color="#949494">
      <van-tabbar-item>
        <span>{{ $t("查件") }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.sendActive : icon.sendInActive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>{{ $t("客服") }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.supportActive : icon.supportInActive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>{{ $t("我的") }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.checklistActive : icon.checklistInActive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Index from "@/views/Index.vue";
import Help from "@/views/Help.vue";
import UserCenter from "@/views/UserCenter.vue";
import { EventBus } from "@/utils/event-bus.js";

import { fetchUserInfo } from "@/service/api";

import { Tabbar, TabbarItem, Toast } from "vant";
import sendActive from "../assets/icons/send-active.png";
import sendInActive from "../assets/icons/send-inactive.png";
import supportActive from "../assets/icons/support-active.png";
import supportInActive from "../assets/icons/support-inactive.png";
import checklistActive from "../assets/icons/checklist-active.png";
import checklistInActive from "../assets/icons/checklist-inactive.png";

export default {
  name: "Home",
  components: {
    Index,
    Help,
    UserCenter,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: 0,
      userInfo: null,
      icon: {
        sendActive: sendActive,
        sendInActive: sendInActive,
        supportActive: supportActive,
        supportInActive: supportInActive,
        checklistActive: checklistActive,
        checklistInActive: checklistInActive,
      },
    };
  },
  activated() {
    const page = this.$route.query.page;

    switch (page) {
      case "orders":
        this.active = 0;
        break;
      case "help":
        this.active = 1;
        break;
      case "profile":
        this.active = 2;
        break;
    }
  },
  mounted() {
    EventBus.$on("orders", (msg) => {
      this.active = 0;
    });

    const webType = this.$route.params.webType || this.$route.query.webType;
    console.log('webType', webType);
    if (webType) {
      this.fetchUserInfo(webType);
    }
  },
  methods: {
    tabChange() {
      document.body.scrollIntoView();
    },
    toTargetPage() {
      setTimeout(() => {
        this.$router.push({ name: "Contact" });
      }, 2000);
    },
    async fetchUserInfo(webType) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await fetchUserInfo();

      if (res && res.success) {
        this.userInfo = res.data;

        const {
          logisticCompany: { phone, address, receiver },
        } = this.userInfo;

        const locale = localStorage.getItem("locale") || "th-TH";
        let hName;
        switch (locale) {
          case "zh-TW":
            hName =
              webType === "cargo-category"
                ? "cargo_category-cn.html"
                : "services-cn.html";
            break;
          case "th-TH":
            hName =
              webType === "cargo-category"
                ? "cargo_category-th.html"
                : "services-th.html";
            break;
          default:
            hName =
              webType === "cargo-category"
                ? "cargo_category-en.html"
                : "services-en.html";
            break;
        }

        let webUrl;

        if (webType === "cargo-category") webUrl = "/category/" + hName;
        else
          webUrl =
            "/services/" +
            hName +
            "?phone=" +
            phone +
            "&address=" +
            encodeURIComponent(address) +
            "&receiver=" +
            receiver;

        // window.open(webUrl, "_blank");
        // EventBus.$emit("openUrl", webUrl);
        window.location.href = webUrl;
      } else Toast.fail(res.message);

      Toast.clear();
    },
  },
};
</script>
<style lang="less"></style>
