export default {
    '没有更多了': '沒有更多了',
    '加载中...': '加載中...',
    '复制成功': '複製成功',
    '确认': '確認',
    '确定': '確定',
    '取消': '取消',
    '暂无数据': '暫無數據',
    '查件': '查件',
    '客服': '客服',
    '我的': '我的',
    '搜索快递单号': '搜索快遞單號',
    '搜索': '搜索',
    '发往泰国(total)': '發往泰國({total})',
    '发往泰国': '發往泰國',
    '点击支付 >>': '您有{total}個包裹到達泰國倉,支付後派送上門。點擊支付 >>',
    '暂无发往泰国运单': '暫無發往泰國運單',
    '派送上门(total)': '派送上門({total})',
    '派送上门': '派送上門',
    '暂无派送上门运单': '暫無派送上門運單',
    '已签收': '已簽收',
    '件': '件',
    '暂无已签收运单': '暫無已簽收運單',
    '小蜜蜂转运优惠券': '小蜜蜂轉運優惠券',
    '券': '券',
    '折': '折',
    '无门槛': '無門檻',
    '满可用': '滿{amount}銖可用',
    '领取后请到[我的-优惠劵]查看': '領取後請到[我的-優惠劵]查看',
    '填写收件地址后可以领取优惠券': '填寫收件地址後可以領取優惠券',
    '一键领取': '一鍵領取',
    '前往填写收件地址': '前往填寫收件地址',
    '您已成功领取': '您已成功領取',
    '领取失败,稍后再试': '領取失敗,稍後再試',
    '客服微信': '客服微信',
    '[点击复制]': '[點擊複製]',
    '语言切换': '語言切換',
    '使用说明': '使用說明',
    '时效与价格': '時效與價格',
    '热门问题': '熱門問題',
    '什么不能寄？': "什麼不能寄？",
    '证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。': '證件例如身份證畢業證，煙，酒，易燃易爆物品，危險化學品，生鮮品，植物及種子，動物等違反中泰兩國法律的物品。',
    '丢件或损毁如何赔付？': '丟件或損毀如何賠付？',
    '未保价的包裹按运费三倍赔偿，保价后按保价金额赔偿，贵重物品请务必预报运单并保价。': '未保價的包裹按運費三倍賠償，保價後按保價金額賠償，貴重物品請務必預報運單並保價。',
    '如何付运费？': '如何付運費？',
    '包裹到达泰国后，系统将给您发送运费通知，支付后系统安排配送包裹上门。': '包裹到達泰國後，系統將給您發送運費通知，支付後系統安排配送包裹上門。',
    '包裹到达中国转运仓后，系统将给您发送付款通知，您可以使用泰国银行手机 App 扫码支付。 付款后发往泰国并派送上门': '包裹到達中國轉運倉後，系統將給您發送付款通知，您可以使用手機銀行App 掃描Prompt pay 二維碼付款。',
    '是否有最低重量要求？': '是否有最低重量要求？',
    '是否有最低体积要求？': '是否有最低體積要求？',
    '最低消费0.2m³，少于0.2m³按照0.2m³计费。': '最低消費0.2m³，少於0.2m³按照0.2m³計費，金额是{minimumFee} baht。',
    '单批包裹不满一公斤按一公斤计费，例如您有两个包裹到达泰国仓库，如果两个包裹的总重量不足一公斤, 按一公斤计费。如果您还有包裹在路上，先到的包裹可以暂存泰国仓，等到齐之后一起计费付款，再派送上门。': '單批包裹不滿一公斤按一公斤計費，例如您有兩個包裹到達泰國倉庫，如果兩個包裹的總重量不足一公斤, 按一公斤計費。如果您還有包裹在路上，先到的包裹可以暫存泰國倉，等到齊之後一起計費付款，再派送上門。',
    '用户代码': '用戶代碼',
    '泰国收件地址': '泰國收件地址',
    '点击填写收件地址': '點擊填寫收件地址',
    '预报运单': '預報運單',
    '退回/保价/打木架/到付': '退回/保價/打木架/到付',
    '优惠券': '優惠券',
    '转运仓信息': '轉運倉信息',
    '收件人': '收件人',
    '电话': '電話',
    '地址': '地址',
    '[复制转运仓信息]': '[複製轉運倉信息]',
    '张': '張',
    '无可用券': '無可用券',
    '请您先填写泰国收件地址': '請您先填寫泰國收件地址',
    '单号': '單號',
    '[编辑备注]': '[編輯備註]',
    '[复制单号]': '[複製單號]',
    '增值服务': '增值服務',
    '共铢(不含到付)': '共{amount}銖(不含到付)',
    '等待包裹入库': '等待包裹入庫',
    '重量': '重量',
    'weight 公斤': '{weight} 公斤',
    '长宽高(单位公分)': '長寬高(單位公分)',
    '体积': '體積',
    '运输方式': '運輸方式',
    '计费方式': '計費方式',
    '折扣': '折扣',
    '转运费': '轉運費',
    '尾程运费': '尾程費用',
    '铢': '銖',
    '共铢(不含到付收费)': '共{amount}銖(不含到付收費)',
    '收件地址': '收件地址',
    '登记单号，选择您需要的增值服务': '登記單號，選擇您需要的增值服務',
    '运单号': '運單號',
    '请输入运单号': '請輸入運單號',
    '陆运': '陸運',
    '海运': '海運',
    '海运不满30KG或0.2立方，按30KG收费': '海運不滿30KG或0.2立方，按30KG收費',
    '保价': '保價',
    '保价金额，保费': '保價金額:{declareAmount}，保費:{insuranceFee}',
    '未保价运单按运费三倍赔偿': '未保價運單按運費三倍賠償',
    '打木架': '打木架',
    '到付快递费': '到付快遞費',
    '按中国仓实付金额收费': '按中國倉實付金額收費',
    '中国仓退回': '中國倉退回',
    '备注': '備註',
    '请输入备注': '請輸入備註',
    '我已阅读并同意': '我已閱讀並同意',
    '《服务条款》': '《服務條款》',
    '请填写运单号': '請填寫運單號',
    '单号最多输入60个字符': '單號最多輸入60個字符',
    '单号只能输入字母、数字和横线,长度不超过60': '單號只能輸入字母、數字和橫線,長度不超過60',
    '请选择运输方式': '請選擇運輸方式',
    '请先阅读并同意服务条款': '請先閱讀並同意服務條款',
    '登记成功': '登記成功',
    'count件包裹待支付': '{count}件包裹待支付',
    '首重计费': '首重計費',
    '折扣立减': '折扣立減',
    '请选择优惠券': '請選擇優惠券',
    '无可用优惠券': '無可用優惠券',
    '实付': '實付',
    '支付': '支付',
    '汇率': '匯率',
    '剩余': '剩餘',
    '取消用券': '取消用券',
    '我们将尽快为您配送上门，感谢您的支持。': '我們將盡快為您配送上門，感謝您的支持。',
    '如有问题请联系客服微信': '如有問題請聯繫客服微信',
    '[复制]': '[複製]',
    '前往查件': '前往查件',
    '下单成功': '下單成功',
    '请以英文或泰文填写收件人姓名和地址': "請以英文或泰文填寫收件人姓名和地址",
    '姓名': '姓名',
    '邮编': '郵編',
    '确 定': '確 定',
    '清 空': '清 空',
    '请输入姓名': '請輸入姓名',
    '姓名最多输入30个字符': '姓名最多輸入30個字符',
    '请输入电话': '請輸入電話',
    '电话最少输入8个字符': '電話最少輸入8個字符',
    '电话最多输入20个字符': '電話最多輸入20個字符',
    '请输入详细地址': '請輸入詳細地址',
    '收货地址不能有汉字': '收貨地址不能有漢字',
    '地址最少输入12个字符': '地址最少輸入12個字符',
    '地址最多输入300个字符': '地址最多輸入300個字符',
    '请输入邮编': '請輸入郵編',
    '邮编不能有汉字': '郵編不能有漢字',
    '邮编最少输入5个数字': '郵編最少輸入5個數字',
    '邮编最多输入5个数字': '郵編最多輸入5個數字',
    '新增': '新增',
    '修改': '修改',
    '地址成功': '地址成功',
    '地址失败': ' 地址失敗',
    '月月发券，四季常有': '月月發券，四季常有',
    '每月1号开始可领取月度优惠券': '每月1號開始可領取月度優惠券',
    '可用券': '可用券',
    '有效期至': '有效期至',
    '已过期': '已過期',
    '使用日期': '使用日期',
    '已用券': '已用券',
    '保价金额': '保價金額',
    '请输入人民币金额': '請輸入人民幣金額',
    '保费': '保費',
    '按保价金额的%自动计算': '按保價金額的{rate}%自動計算',
    '取消保险': '取消保險',
    '最高保价金额人民币': '最高保價金額{amount}人民幣',
    '快递单号': '快遞單號',
    '共count件包裹': '共{count}件包裹',
    '收件信息': '收件信息',
    '备注最多不超过15个字符': '備註最多不超過15個字符',
    '编辑成功': '編輯成功',
    '暂时没有找到相关运单': '暫時沒有找到相關運單',
    '请输入要搜索的快递单号': '請輸入要搜索的快遞單號',
    '包裹到达泰国后将通知您支付转运费并配送上门。': '包裹到達泰國後將通知您支付轉運費並配送上門。',
    '继续预报运单': '繼續預報運單',
    '待支付': '待支付',
    '已支付': '已支付',
    '等待入仓': '等待入倉',
    '到达中转仓': '到達中轉倉',
    '泰国仓分拣中': '泰國倉分揀中',
    '您已签收': '您已簽收',
    '安排配送中': '安排配送中',
    '待打包': '待打包',
    '已打包': '已打包',
    '问题件': '問題件',
    '按重量': '按重量',
    '按体积': '按體積',
    '请转账至': '請轉賬至',
    '银行': '銀行',
    '账号': '賬號',
    '户名': '戶名',
    '上传网银截图': '上傳網銀截圖',
    '金额': '金額',
    '请输入充值金额': '請輸入充值金額',
    '账户余额': '賬戶餘額',
    '充值': '充值',
    '用户余额充值': '用戶餘額充值',
    '运费返还': '運費返還',
    '运单赔付': '運單賠付',
    '待审核': '待審核',
    '审核不通过': '審核不通過',
    '暂无记录': '暫無記錄',
    '余额': '餘額',
    '设为默认收件地址': '設為默認收件地址',
    '编辑': '編輯',
    '删除': '刪除',
    '新增地址': '新增地址',
    '请选择收货地址': '請選擇收貨地址',
    '配送方式': '配送方式',
    '请选择配送方式': '請選擇配送方式',
    '余额不足': '餘額不足',
    '登录中...': '登錄中 ...',
    '小蜜蜂物流': '小蜜蜂物流',
    '申请': '申請',
    '获取你的昵称、头像': '獲取你的暱稱、頭像',
    'Line个人信息': 'Line個人信息',
    '允许': '允許',
    '未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。': '未保價的包裹按運費三倍賠償（不超過物值），保價後按保價金額賠償，貴重物品建議您預報運單並保價。易碎品或容易被壓壞的包裹建議您打木架，否則無法保證貨品安全。',
    '如何消费？>>': '如何消費?>>',
    '本地派送预付费用': '本地派送預付費用',
    '的红包': "的紅包",
    '一起来分享': '一起來分享',
    '已存入您的小蜜蜂账户余额，可直接消费': '已存入您的小蜜蜂賬戶餘額，可直接消費',
    '您来迟了,红包已被抢光了!': '您來遲了,紅包已被搶光了!',
    'count个红包已被领取': '{count}個紅包已被領取',
    '乡/市/省/邮编': '鄉/市/省/郵編',
    '请选择省市区': '請選擇省市區',
    '保险': '保險',
    '国内快递费到付': '國內快遞費到付',
    '运费计算器': '運費計算器',
    '尺寸': '尺寸',
    '长': '長',
    '宽': '寬',
    '高': '高',
    '请输入重量': '請輸入重量',
    '国际运费': '國際運費',
    '本地运费': '本地運費',
    '运费': '運費',
    '计算': '計算',
    '请输入长度': '請輸入長度',
    '请输入宽度': '請輸入寬度',
    '请输入高度': '請輸入高度',
    '费用': '費用',
    '货物类别': '貨物類別',
    '重置': '重置',
    '计算结果': '計算結果',
    '打木架费': '打木架費',
    'A类': 'A類',
    'B类': 'B類',
    'C类': 'C類',
    '单位': '單位',
    '查看价格表': '查看價格表',
    '选填': '選填',
    '必填': '必填',
    '预估费用': '預估費用',
    '下拉即可刷新...': '下拉即可刷新...',
    '释放即可刷新...': '釋放即可刷新...',
    '地址管理': '地址管理',
    '地址簿': '地址簿',
    '运单详情': '運單詳情',
    '保险服务': '保險服務',
    '我的优惠券': '我的優惠券',

    '我的余额': '我的餘額',
    '小蜜蜂红包': '小蜜蜂紅包',
    '默认': '默認',
    '选为收件地址': '選為收件地址',
    '没有': '沒有',
    '恭喜获得amount泰铢红包': '恭喜獲得{amount}泰銖紅包',
    '分享给小伙伴，大家一起抢': '分享給小伙伴，大家一起搶',
    '分享到Line领取': '分享到Line領取',
    '下次再说': '下次再說',
    '自提': '自提',
    '小蜜蜂': '小蜜蜂',
    'JD': 'JD',
    'Deliveree': 'Deliveree',
    '您已经登记此包裹，可在「查件」页面搜索单号': '您已經登記此包裹，可在[查件]頁面搜索單號',
    '中转仓支付后发出。点击支付 >>': '您有{total}個包裹到達中轉倉,支付後發出。點擊支付 >>',
    '待付款': '待付款',
    '暂无待付款运单': '暂无待付款运单',
    
    '已揽收': '已揽收',
    '已退回泰国仓': '已退回泰国仓',
    '出库中': '出库中',
    
    '暂不领取': '暫不領取',
    '付陆运包裹费用': '付陸運包裹費用',
    '付海运包裹费用': '付海运包裹费用',
    '陆运海运包裹统计': '您有{totalByLand}個陸運包裹，{totalBySea}個海運包裹',
    '正在计算增值服务费用': '正在計算增值服務費用',
    '审核中': '審核中',
    '昵称': '昵称',
    '支付有余额': '共需支付{totalDiscountedForeignFee}铢，其中余额支付{balance}铢{cashPayAmount}，支付后发往泰国，如需留仓集包，可等包裹到齐再付。',
    '支付无余额': '共需支付{totalDiscountedForeignFee}铢，其中扫码支付{totalDiscountedForeignFee}铢，支付后发往泰国，如需留仓集包，可等包裹到齐再付。',
    '留仓集包提示':'支付后发往泰国，如需留仓集包，可等包裹到齐再付。',
    '扫码支付': '扫码支付',
    'Please use PromptPay to scan this QRCode': '請使用PromptPay掃碼',
    'DO NOT pay more than once!': '請不要重新支付!',
    '1. Screenshot this OR Code.': '1. 截圖保存二維碼.',
    '2. Open mobile banking app on your pohone.': '2. 打開你手機上的銀行app.',
    "3. Select 'Scan' or 'QR code', then choose 'Open Picture'": "3. 選擇'掃一掃'或'二維碼'，然後選擇'開啟圖片'",
    "4. Select the QR Code you have received and pay": '4. 選擇你儲存的二維碼並支付',
    '余额支付': '余额支付',
    '充值提示': '充值提示',
    '前往充值': '前往充值',
    '前往充值提示': '您的账户余额{balance}铢，共需支付{totalDiscountedForeignFee}铢，余额不足，是否前往充值？',
      
    '普货': '普貨',
    '敏感货': '敏感貨',
    '特殊货': '特殊貨',
    '拒收': '拒收',
    '退回': '退回',
    '加固包装': '加固包裝',
    '支付运费': '支付運費',
    '红包': '紅包',
    '提现': '提現',
    '赔付': '賠付',
    '未通过,请联系客服': '未通過,請聯繫客服',
    '交易成功': '交易成功',
    '微信': '微信',
    '支付宝': '支付寶',
    '转账': '轉賬',
    '领取': '领取',
    '到达泰国仓': '到達泰國倉',
    '已取消': '已取消',
    '司机': '司機',
    '交易记录':'交易記錄',
    '最低0.2立方米计费': '最低0.2立方米计费',
    '门牌号':'门牌号，格式：xxxx/xxxx',
    '门牌号，格式：xxxx/xxxx': '门牌号，格式：xxxx/xxxx',
    '门牌号需为数字，格式xxxx/xxxx，如：239/8': '门牌号需为数字，格式xxxx/xxxx，如：239/8',
    '门牌号最多输入20个字符': '门牌号最多输入20个字符',
    '请输入门牌号': '请输入门牌号',
    '支付未成功，请确认已付款': '支付未成功，请确认已付款',
    '完成支付': '完成支付',
}