import axios from '@/utils/request';

export async function fetchUserInfo() {
    return axios.get('user/current');
}

export async function getDefaultContact() {
    return axios.get('contact/getDefault');
}

export async function toBePay() {
    return axios.get('cargo/pay/toBePay');
}

export async function groupCount() {
    return axios.get('cargo/groupCount');
}

export async function cargoList(params) {
    return axios.get('cargo/list', params);
}

export async function localdelivery(params) {
    return axios.get('cargo/localdelivery', params);
}

export async function countByStatus(params) {
    return axios.get('cargo/countByStatus', params);
}

export async function contactDetail(id) {
    return axios.get('contact/detail/' + id);
}

export async function addContact(params) {
    return axios.post('contact/add', params)
}

export async function editContact(id, params) {
    return axios.put('contact/edit', id, params)
}

export async function cargoDetail(id) {
    return axios.get('cargo/detail/' + id);
}

export async function setRemark(params) {
    return axios.post('cargo/setRemark', params)
}

export async function getAddedValues() {
    return axios.get('cargo/getAddedValues?currency=THB');
}

export async function register(payload) {
    return axios.post('cargo/register', payload);
}

export async function availbleList(params) {
    return axios.get('coupon/availbleList', params);
}

export async function availbleCount(params) {
    return axios.get('coupon/availbleCount', params);
}

export async function getNotice() {
    return axios.get('notice/list', { sorter: "id_desc" });
}

export async function getCargoList(payload) {
    return axios.get('cargo/list', { ...payload, currentPage: 1, pageSize: 100 })
}

export async function getClaimableCouponList() {
    return axios.get('coupon/claimableList');
}

export async function claim(payload) {
    return axios.post('coupon/claim', payload);
}

export async function getLocalDeliveryDetail(id) {
    return axios.get('cargo/localdelivery/detail/' + id);
}

export async function preorder(payload) {
    return axios.post('cargo/pay/preorder', payload)
}

export async function getBankInfo() {
    return axios.get('account/getBankInfo');
}

export async function uploadScreenshot(payload) {
    return axios.post('account/uploadScreenshot', payload, { 'Content-Type': 'multipart/form-data' });
}

export async function transactions(payload) {
    return axios.get('account/transactions', payload);
}

export async function order(payload) {
    return axios.post('cargo/pay/order', payload);
}

export async function pay(payload) {
    return axios.post('cargo/pay', payload);
}

export async function topupList(payload) {
    return axios.get('balance/topupList', payload);
}

export async function topupDetail(id) {
    return axios.get('balance/topupDetail/' + id);
}

export async function topupConfirm(payload) {
    return axios.post('balance/topup/confirm', payload);
}

export async function getContactList() {
    return axios.get('contact/getAll');
}

export async function setDefaultContact(id) {
    return axios.put('contact/setDefault', id);
}

export async function deleteContact(id) {
    return axios.delete('contact/delete', id);
}

export async function redPacketDetail(redPacketId) {
    return axios.get('redpacket/detail/' + redPacketId);
}

export async function redPacketOpen(redPacketId) {
    return axios.post('redpacket/open/' + redPacketId);
}

export async function redPacketSend(orderNo) {
    return axios.post('redpacket/send/' + orderNo);
}

export async function refreshAccessToken(refreshToken) {
    return axios.post('refreshToken?refreshToken=' + refreshToken, {})
}

export async function lineLogin(payload) {
    return axios.post('lineLogin', payload);
}

export async function searchGeo(keyWord) {
    return axios.get('contact/searchGeo', { keyword: keyWord });
}

export async function getFollowUpList(cargoIds) {
    return axios.get('followup?cargoIds=' + cargoIds)
}

export async function calMinimumFee(payload) {
    return axios.get('cargo/fee/calMinumueFee', payload)
}

export async function feeEstimation(payload) {
    return axios.get('cargo/fee/estimation', payload)
}

export async function localRouteFeeEstimation(payload) {
    return axios.get('cargo/localRouteFee/estimation', payload)
}

export async function woodenframeEstimation(payload) {
    return axios.get('cargo/woodenframe/estimation', payload)
}

export async function getPickupPointCount(payload) {
    return axios.get('pickuppoint/getCount', payload)
}
