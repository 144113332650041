var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user_center"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header_bg",attrs:{"src":require("../assets/images/bg.jpg")}}),_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"header_user"},[_c('van-image',{attrs:{"round":"","width":"60px","height":"60px","src":(_vm.userInfo && _vm.userInfo.user.avatar) ||
          require('../assets/images/logo.jpeg')}}),_c('div',{staticClass:"user"},[(_vm.userInfo && _vm.userInfo.user.nickName)?_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(_vm.userInfo.user.nickName)+" "),_c('van-icon',{attrs:{"name":"edit","bind:tap":"gotoProfile"}})],1):_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(_vm._s(_vm.$t('昵称'))+" "),_c('van-icon',{attrs:{"name":"edit","bind:tap":"gotoProfile"}})],1),_c('div',{staticStyle:{"font-size":"0.9em","color":"#a8a8a8"}},[_vm._v(" ID："+_vm._s(_vm.userInfo && _vm.userInfo.user.code26))])])],1)]),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"col",on:{"click":_vm.goToBalance}},[_c('div',{staticClass:"value"},[_vm._v("-")]),_c('div',{staticClass:"name"},[_c('van-icon',{attrs:{"name":"balance-list-o"}}),_vm._v(" "+_vm._s(_vm.$t('交易记录'))+">")],1)]),_c('span',{staticClass:"divider"}),_c('div',{staticClass:"col",on:{"click":_vm.goToCoupons}},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.availableCouponCount ? _vm.availableCouponCount : '0'))]),_c('div',{staticClass:"name"},[_c('van-icon',{attrs:{"name":"coupon-o"}}),_vm._v(" "+_vm._s(_vm.$t('优惠券'))+">")],1)])])]),_c('van-cell-group',{staticClass:"contact-info"},[(_vm.defaultContact && _vm.defaultContact.id)?_c('van-cell',{attrs:{"title":_vm.$t('泰国收件地址'),"value":_vm.defaultContact.name + '/' + _vm.defaultContact.mobile,"to":{
        name: 'contactList',
        query: {
          scene: 'edit',
        },
      },"is-link":""}}):_c('van-cell',{attrs:{"title":_vm.$t('泰国收件地址'),"value":_vm.$t('点击填写收件地址'),"to":{
      name: 'contactList',
      query: {
        scene: 'edit',
      },
    },"is-link":""}}),_c('van-cell',{attrs:{"title":_vm.$t('预报运单'),"to":{ name: 'cargoRegister' },"value":_vm.$t('退回/保价/打木架/到付'),"is-link":""}})],1),_c('van-cell-group',{staticClass:"warehouse-info",attrs:{"title":_vm.$t('转运仓信息')}},[_c('van-cell',{attrs:{"title":_vm.$t('收件人'),"value":_vm.mWarehouse.receiver}}),_c('van-cell',{attrs:{"title":_vm.$t('电话'),"value":_vm.mWarehouse.phone}}),_c('van-cell',{attrs:{"title":_vm.$t('地址'),"value":_vm.mWarehouse.address}})],1),_c('div',{staticClass:"copy-button",on:{"click":_vm.copyWarehouse}},[_vm._v(" "+_vm._s(_vm.$t("[复制转运仓信息]"))+" ")]),_c('div',{staticStyle:{"height":"50px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }