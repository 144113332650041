import axios from 'axios'
import {
    Toast
} from "vant"
import {
    BASE_API,
    COMPANY
} from "./constants"
import router from '../router'
import {
    getAccessToken, clearAccessToken
} from "@/utils/token";

const SERVER_API = BASE_API;

const noNeedTokenUrls = ['refreshToken', 'lineLogin']

const service = axios.create({
    timeout: 10000,
    baseURL: SERVER_API,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'logistic-company': COMPANY
    }
})

// 添加请求拦截器
service.interceptors.request.use(config => {
    const access_token = getAccessToken();
    // const access_token = 'c8eb40d5-e60b-4769-811d-443d68490e63'; //BATSX
    // const access_token = '88b168f5-86e9-46c3-9ae1-0e8503946921'; //production bazzn
    // const access_token = '048b1310-e48d-4106-bfad-6635e024be79'

    const currentUrl = config.url
    const res = noNeedTokenUrls.filter(url => {
        return currentUrl.indexOf(url) != -1
    })

    if (!res || res.length === 0)
        if (access_token) {
            config.headers.Authorization = 'Bearer ' + access_token;
        }

    return config
})

service.interceptors.response.use(response => {
    if (response.status === 200) {
        const data = response.data
        if (data.code === 40004) {
            Toast.fail(data.message)
            clearAccessToken()
            router.replace({
                name: 'login'
            })
        }
        return data
    }
}, error => {
    Toast("network exception")
    return Promise.reject(error)
})

const request = {
    baseURL: SERVER_API,
    getDetails: (path, id, params) => {
        return service({
            url: path + '/' + id,
            params: params,
            method: 'GET'
        })
    },
    get: (path, params) => {
        return service({
            url: path,
            params: params,
            method: 'GET'
        })
    },
    post: (path, params, headers) => {
        return service({
            url: path,
            data: params,
            headers: headers
        })
    },
    put: (path, id, params) => {
        return service({
            url: path + '/' + id,
            data: params,
            method: 'PUT'
        })
    },
    delete: (path, id) => {
        return service({
            url: path + '/' + id,
            method: 'DELETE'
        })
    },
}

export default request