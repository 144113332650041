var storage = {
    set(key, value) {
        localStorage.setItem(key, value);
    },
    get(key) {
        return localStorage.getItem(key);
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    setObj(key, value) {
        const v = JSON.stringify(value)
        localStorage.setItem(key, v);
    }
}
export default storage
