import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: '地址管理'
    }
  },
  {
    path: '/contactList',
    name: 'contactList',
    component: () => import('../views/contact/Contact.vue'),
    meta: {
      title: '地址簿'
    }
  },
  {
    path: '/cargo/detail',
    name: 'cargoDetail',
    component: () => import('../views/cargo/Detail.vue'),
    meta: {
      title: '运单详情'
    }
  },
  {
    path: '/remark',
    name: 'remark',
    component: () => import('../views/Remark.vue'),
    meta: {
      title: '备注'
    }
  },
  {
    path: '/cargo/register',
    name: 'cargoRegister',
    component: () => import('../views/cargo/Register.vue'),
    meta: {
      keepAlive: true,
      title: '预报运单'
    }
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import('../views/Insurance.vue'),
    meta: {
      title: '保险服务'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('../views/Coupon.vue'),
    meta: {
      title: '我的优惠券'
    }
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    component: () => import('../views/SearchResult.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/OrderDetails.vue')
  },
  {
    path: '/order/payChoice',
    name: 'paychoice',
    component: () => import('../views/paychoice/Index.vue'),
  },
  {
    path: '/order/order',
    name: 'order',
    component: () => import('../views/order/Order.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/prompt',
    name: 'prompt',
    component: () => import('../views/prompt/Index.vue'),
  },
  {
    path: '/order/success',
    name: 'orderSuccess',
    component: () => import('../views/order/Success.vue')
  },
  {
    path: '/balance',
    name: 'balance',
    component: () => import('../views/recharge/Balance.vue'),
    meta: {
      title: '我的余额'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge/Recharge.vue')
  },
  {
    path: '/redpacket',
    name: 'redpacket',
    component: () => import('../views/redpacket/RedPacket.vue'),
    meta: {
      title: '小蜜蜂红包'
    }
  },
  {
    path: '/topupList',
    name: 'topupList',
    component: () => import('../views/audit/TopupList.vue')
  },
  {
    path: '/topupDetail',
    name: 'topupDetail',
    component: () => import('../views/audit/TopupDetail.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/Index.vue')
  },
  {
    path: '/followup',
    name: 'followup',
    component: () => import('../views/followup/Index.vue'),
    meta: {
      title: '增值服务'
    }
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('../views/calculator/Index.vue'),
    meta: {
      keepAlive: true,
      title: '运费计算器'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router